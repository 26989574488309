/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

body {
  padding: 0 !important;
}

.breadcrumb-item::marker {
  content: "|";
  color: lightgray;
}

.main-text-bolder {
  font-weight: 500;
  color: #5e5873;
}

.section-outlined {
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}

.section-title {
  font-weight: 600;
  font-size: 15px;
  color: #5e5873;
}

.page-title {
  font-weight: 500;
  color: #5e5873;
  font-size: 24px;
}

.wrapper-content {
  background: #ffffff;
  box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-top: 20px;
  padding: 30px;
}

.condition-wrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.access-conditions {
  color: #009fe3;
  font-weight: 700;
}

.total-access-conditions {
  background-color: #009fe3;
  color: white;
  font-weight: 700;
  width: 15px;
  height: 15px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 8px;
}

.purchase-message {
  resize: none;
  height: 100%;
}

.total-balance-wrapper {
  box-shadow: 0px 6px 19px rgba(235, 87, 87, 0.05);
  border-radius: 6px;
  padding: 20px;
}

.total-balance-wrapper.no-balance {
  border: 1px solid #eb5757;
}

.total-balance {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.cost {
  font-size: 20px;
  color: #454545;
}

.coin-type,
.balance {
  color: #454545;
}

.checkout-disabled {
  background-color: red;
}

.no-balance-label {
  color: #eb5757;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
}
.product-infos {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px;
}

.total-value {
  font-weight: 700;
  border-top: 1px solid #d0d0d0;
  padding-top: 10px;
}

.triple-line-max {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}

.long-description {
  white-space: pre-line;
  word-wrap: break-word;
}

// GoSwiper
.go-swipper {
  padding: 0.5rem 0;
  .go-swipper-slides-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    gap: 1rem;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .go-swipper-slide {
    cursor: pointer;
  }
}

// Benefits
.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .benefit-and-collectible-fields {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;

    .benefit-field {
      display: flex;
      justify-content: flex-start;
      width: 12rem;
      size: scale-down;
    }

    .collectible-field {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 12rem;
    }
  }

  .benefit-status-and-date-fields {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;

    .status-field {
      width: 12rem;
      justify-self: flex-start;
    }

    .benefit-date-field {
      width: 12rem;
    }
  }

  .benefit-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;

    .benefit-details-button {
      padding-right: 2rem;
      padding-left: 2rem;
      width: 12rem;
    }

    .qrcode-field {
      justify-content: flex-start;
      width: 12rem;
    }
  }

  @media (min-width: 575px) {
    .benefit-and-collectible-fields {
      gap: 5rem;
    }
    .benefit-status-and-date-fields {
      gap: 5rem;
    }
    .benefit-buttons {
      gap: 5rem;
    }
  }

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: center;

    .benefit-and-collectible-fields {
      justify-content: flex-start;
      gap: 1rem;

      .benefit-field,
      .collectible-field {
        display: flex;
        width: 100%;
      }
    }
    .benefit-status-and-date-fields {
      flex-direction: column;
      gap: 1rem;
    }
    .benefit-buttons {
      height: 100%;
      gap: 2rem;

      .qrcode-field {
        margin: 0 !important;
      }
    }
  }

  @media (min-width: 850px) {
    .benefit-and-collectible-fields {
      gap: 2rem;
    }
    .benefit-status-and-date-fields {
      gap: 2rem;
    }
    .benefit-buttons {
      gap: 3rem;
    }
  }

  @media (min-width: 950px) {
    .benefit-and-collectible-fields {
      width: 35rem;
    }

    .benefit-status-and-date-fields {
      width: 20rem;
    }

    .benefit-buttons {
      flex-direction: row;
      flex-wrap: nowrap;
      width: initial;

      .qrcode-field {
        zoom: 1.3;
      }
    }
  }

  @media (min-width: 1300px) {
    gap: 5rem;

    .benefit-and-collectible-fields {
      .benefit-field {
        display: flex;
        flex-direction: column;
        width: 12rem;
      }

      .collectible-field {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 15rem;
      }
    }
  }

  @media (min-width: 1350px) {
    gap: 1rem;

    .benefit-and-collectible-fields {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .benefit-status-and-date-fields {
      .status-field {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 10rem;
      }

      .benefit-date-field {
        display: none;
      }
    }

    .benefit-buttons {
      .benefit-details-button {
        width: 12rem;
      }

      .qrcode-field {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 10rem;
        zoom: normal;
      }
    }
  }

  @media (min-width: 1450px) {
    gap: 0.8rem;

    .benefit-and-collectible-fields {
      gap: 0.5rem;
    }

    .benefit-status-and-date-fields {
      flex-direction: row;
      flex-wrap: nowrap;

      .benefit-date-field {
        display: inline;
        width: 10rem;
      }
    }

    .benefit-buttons {
      gap: 1rem;
    }
  }

  @media (min-width: 1490px) {
    gap: 1rem;
    justify-content: space-around;

    .benefit-and-collectible-fields {
      justify-content: space-around;
      width: 100%;
      gap: 1rem;
    }

    .benefit-status-and-date-fields {
      justify-content: space-around;
      width: 100%;
      gap: 1rem;
    }

    .benefit-buttons {
      justify-content: space-around;
      width: 100%;
      gap: 1.5rem;
    }
  }
}

.product-codes-list-div {
  max-height: 10rem;
  overflow-y: auto;
}
